import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput as ChatMessageInput,
  TypingIndicator
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './Game.css';
import { getBlobContents, blobExists, uploadBlobContents, appendToBlobContents } from '../utils/blobStorage';
import { openaiCompletions } from '../utils/openai';
import { v4 as uuidv4 } from 'uuid';

const Game = () => {
  const { chat_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = location.state || {}; // Access the name from the state
  
  // Hardcoded chat history with 1 system, 1 assistant, and 1 user message
  const [chatHistory, setChatHistory] = useState([]);
  
  const [question, setQuestion] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  // State variables for diagnostic guesses
  const [guess1, setGuess1] = useState('');
  const [guess2, setGuess2] = useState('');

  const askQuestion = useCallback(async () => {
    if (question.trim() === '') return;

    setIsTyping(true);
    const userMessage = { role: 'user', content: question };
    
    setQuestion('');
    // Update chat history with user message before making API call
    setChatHistory(prevHistory => [...prevHistory, userMessage]);

    const data = await openaiCompletions([...chatHistory, userMessage]);

    if (data.error) {
      console.error('Error getting response from OpenAI:', data.error);
      setChatHistory(prevHistory => [...prevHistory, { role: 'assistant', content: "I'm sorry, I'm having trouble processing your message. Please try again." }]);
    } else {
      // Check if the response contains the expected structure
      if (data.choices && data.choices.length > 0) {
        const assistantMessage = data.choices[0].message;
        setChatHistory(prevHistory => [...prevHistory, assistantMessage]);
        await uploadBlobContents(`${name}/${chat_id}.json`, JSON.stringify([...chatHistory, userMessage, assistantMessage]));
      } else {
        console.error('Unexpected response structure:', data);
        setChatHistory(prevHistory => [...prevHistory, { role: 'assistant', content: "I'm sorry, I'm having trouble processing your message. Please try again." }]);
      }
    }

    setIsTyping(false);
  }, [question, chatHistory, name, chat_id]);

  const loadChatHistory = useCallback(async () => {
    const blobExistsRes = await blobExists(`${name}/${chat_id}.json`);
    if (blobExistsRes) {
      const chatHistory = await getBlobContents(`${name}/${chat_id}.json`);
      if (chatHistory) {
        setChatHistory(JSON.parse(chatHistory));
        return;
      } else {
        console.error("no chat history found");
      }
    }

    // Load system prompt from blob storage
    const systemPrompt = await getBlobContents('system-prompt.txt');
    if (!systemPrompt) {
      console.error("Failed to load system prompt from blob storage, using fallback prompt");
    }

    setChatHistory([
      { role: 'system', content: systemPrompt || `### **Prompt: Simulating a Complex Patient**

You are now a simulated patient, designed to challenge and rigorously test a doctor’s diagnostic abilities. Follow these instructions carefully to ensure a high level of complexity:

### 1. **Assume the Role of a Realistic and Complex Patient:**

- Take on the persona of a patient with a **difficult-to-diagnose medical condition**, featuring **overlapping symptoms** or **comorbidities** that mimic multiple potential conditions.
- Incorporate realistic patient details such as age, gender, occupation, lifestyle, and medical history, maintaining consistency throughout the interaction.

### 2. **Present Ambiguous and Overlapping Symptoms:**

- Start with **vague primary symptoms** that are common across several conditions.
- Gradually disclose additional symptoms only when prompted by specific, well-targeted questions from the doctor. Ensure these symptoms are ambiguous and could fit various conditions.
- **Include patient uncertainty** in responses (e.g., “It comes and goes,” or “I think it’s worse at night, but I’m not sure”), adding a layer of complexity.
- **Restrict symptom details**:
    - Avoid volunteering information about duration, triggers, severity, or frequency unless the doctor asks directly.
    - Limit responses to only one symptom at a time, and do not elaborate unless explicitly prompted.
- Respond to open-ended questions (e.g., “Tell me more” or “What else is going on?”) with, “Is there something specific you want to know about?” to maintain diagnostic difficulty.
- Occasionally **redirect the conversation** back to the initial symptoms to simulate patients who might struggle with articulating symptoms clearly.

### 3. **Introduce Diagnostic Complexity and Red Herrings:**

- Withhold key information that would make the diagnosis straightforward, requiring doctors to ask thorough, precise questions.
- Introduce **red herrings**—symptoms or history that may not be relevant to the primary condition but could be mistaken for it.
- Integrate **atypical symptoms** of common conditions or rare symptoms that only partially align with the primary condition to force broader differential diagnoses.

### 4. **Replicate Real-world Diagnostic Challenges:**

- Ensure the simulation mimics real-life encounters where patients may present atypically or provide unclear, incomplete information.
- Respond vaguely to questions about previous tests, results, or treatments (e.g., “I had a scan, but I can’t remember what it showed”), simulating poor patient recall.
- Include **unusual symptom triggers**, unexpected symptom progressions, or rare manifestations to increase diagnostic difficulty.

### 5. **Maintain Diagnostic Rigor:**

- Avoid providing clear clues unless explicitly requested.
- Add nuances that encourage doctors to consider less common differential diagnoses, such as:
    - Symptoms that overlap across different organ systems.
    - Gradual changes in symptom descriptions that align with multiple conditions.
    - Unexpected improvements or worsening of symptoms that do not follow a typical pattern.

### 6. **Implement Adjustable Difficulty Levels:**

- Use **dynamic difficulty adjustments**:
    - **If the doctor quickly identifies the correct diagnosis**, increase the next case's complexity by adding rare symptoms, unusual combinations of common symptoms, or comorbidities.
    - Include **uncommon conditions** that share symptoms with more common diseases but require additional probing for accurate diagnosis.

### 7. **Create Unique and Diverse Patient Profiles for Each Interaction:**

- Generate a new patient profile for each interaction with varying:
    - **Demographics**: Name, age, sex, ethnicity, occupation, lifestyle, and past medical history.
    - **Presenting conditions**: Include a broad spectrum of diseases, ensuring unique and challenging presentations each time.
    - **Complex medical histories**, such as overlapping conditions, unusual past medical events, and detailed social histories, that could contribute to the symptoms.
    - **Consistent symptom presentation** that aligns with the patient's demographics (e.g., no menopausal symptoms in a male patient).
- **Format of Initial Message**:
    - Include name, age, sex, ethnicity, and chief complaint in a single line, followed by:
        - **PMH** (Past Medical History)
        - **Meds** (Current Medications)
        - **SurgHx** (Surgical History)
        - **SH** (Social History)
        - **Allergies**
    - Provide this formatted information before the doctor begins asking questions.

### 8. **Add Emotional and Behavioral Elements:**

- Introduce **emotional dynamics**, such as anxiety, frustration, or impatience, to simulate real-world interactions and add an extra layer of complexity.
- Adjust responses to reflect the patient's mood, such as becoming more vague or defensive when pressed too hard for details, simulating real patient behaviors.

### 9. **Handling Diagnoses and Providing Feedback:**

- When the doctor proposes a diagnosis (formatted as “Diagnoses: Diagnosis 1, Diagnosis 2”), respond with:
    - A **“thank you”** followed by the correct diagnosis.
    - Confirmation of whether any of the proposed diagnoses are correct.
    - **Detailed feedback** about missed questions, missed symptoms, or diagnostic opportunities to reinforce learning.
    - Highlight **commendable diagnostic reasoning steps**, along with gaps in the doctor’s process.
    - Invite the doctor to give feedback if they disagree with the simulation’s diagnosis, or prompt them to click the “next patient” button to continue.

### 10. **Maintain Difficulty Yet Fairness:**

- Ensure that the information provided is sufficient for an accurate diagnosis but only if the doctor demonstrates strong diagnostic reasoning and thorough questioning.
- Keep the simulation fair by allowing for correct diagnoses when doctors display comprehensive, thoughtful diagnostic approaches, rewarding meticulous medical thinking.` }
    ]);

    setQuestion("What brings you into the clinic today?");
  }, [name, chat_id]);

  useEffect(() => {
    if (!name) {
      navigate('/');
      return;
    }
    loadChatHistory();
  }, [name, navigate, loadChatHistory]);

  useEffect(() => {
    if (question === "What brings you into the clinic today?") {
      askQuestion();
    }
  }, [question, askQuestion]);

  const handleReturnHome = () => {
    navigate('/');
  };

  const handleStartNewGame = async () => {
    navigate(`/game/${uuidv4()}`, { state: { name } });
  };

  // Handler for submitting diagnostic guesses
  const handleSubmitDiagnostics = async () => {
    setIsTyping(true);

    // Add a separator to the chat history
    setChatHistory(prevHistory => [...prevHistory, { role: 'separator' }]);

    // Construct the differential diagnostic string conditionally
    let differentialDiagnostic = `Differential Diagnoses: ${guess1}`;
    if (guess2.trim() !== '') {
      differentialDiagnostic += `, ${guess2}`;
    }

    const diffDiagnosticMessage = { role: 'user', content: `Based on the conversation so far, please evaluate if the physician's differential diagnoses are correct: ${differentialDiagnostic}. Respond with a JSON object containing a boolean field 'is_correct' and a string field 'message' explaining the evaluation. In the message, it is important to include the correct diagnosis, because this is the end of the chat session.` };

    // Make an openai completions call with this differential diagnostic message
    // Call openai completion endpoint based on the message history
    const data = await openaiCompletions([...chatHistory, diffDiagnosticMessage], { type: 'json_object' });

    // Add the differential diagnostic message to the chat history
    const userMessage = { role: 'user', content: differentialDiagnostic };
    var assistantMessage;

    if (data.error) {
      console.error('Error getting response from OpenAI:', data.error);
      assistantMessage = { role: 'assistant', content: "I'm sorry, I'm having trouble processing your message. Please try again." };
    } else {
      const diffDiagnosticResponse = JSON.parse(data.choices[0].message.content);
      assistantMessage = { role: 'assistant', content: diffDiagnosticResponse.message };

      const blobNameToAppendTo = diffDiagnosticResponse.is_correct ? `${name}/correct.txt` : `${name}/incorrect.txt`;
      await appendToBlobContents(blobNameToAppendTo, JSON.stringify([...chatHistory, userMessage, assistantMessage]));
    }

    // Use the response to update the chat history
    setChatHistory(prevHistory => [...prevHistory, userMessage, assistantMessage]);
    await uploadBlobContents(`${name}/${chat_id}.json`, JSON.stringify([...chatHistory, userMessage, assistantMessage]));
    setIsTyping(false);

    // Reset the input fields after submission
    setGuess1('');
    setGuess2('');
  };

  // New function to check if the submit button should be disabled
  const isSubmitDisabled = () => {
    const hasSeparator = chatHistory.some(message => message.role === 'separator');
    const noGuesses = guess1.trim() === '' && guess2.trim() === '';
    return hasSeparator || noGuesses;
  };

  // Function to return a number based on the first 3 characters of the chat_id interpreted as hex
  const getPatientNumber = () => {
    return parseInt(chat_id.substring(0, 4), 16);
  };

  return (
    <div className="game-container">
      <div className="chat-container">
        <h1 className="game-title">Patient #{getPatientNumber()}</h1>
        <ChatContainer className="chat-box">
          <MessageList
            scrollBehavior="smooth"
            typingIndicator={isTyping ? <TypingIndicator content="Patient is typing..." /> : null}
          >
            {chatHistory
              .filter(message => message.role !== 'system')
              .map((message, index) => {
                if (message.role === 'separator') {
                  return (
                    <div
                      key={index}
                      className="separator"
                    />
                  );
                }

                return (
                  <Message
                    key={index}
                    model={{
                      sender: message.role === 'user' ? 'You' : 'Assistant',
                      message: message.content,
                      direction: message.role === 'user' ? 'outgoing' : 'incoming',
                    }}
                  />
                );
              })}
          </MessageList>
          <ChatMessageInput
            value={question}
            onChange={setQuestion}
            onSend={askQuestion}
            attachButton={false}
            placeholder="Ask a question"
            className="chat-message-input"
          />
        </ChatContainer>
      </div>

      <div className="info-container">
        <div className="rules-section">
          <h2><u>rules / objective:</u></h2>
          <p><strong>tl;dr:</strong> conduct a proper history, ask specific questions, come up with 1-2 diagnoses that are most likely based on the symptoms, and click submit to see if you are right.</p>
          <ul>
            <li>the chat on the left simulates a patient with a specific set of symptoms.</li>
            <li>your goal is to diagnose the patient.</li>
            <li>treat this patient conversation exactly how you would a patient in real life.</li>
            <li>some symptoms the patient will openly share, others you will need to use your clinical deductive skills to uncover.</li>
            <li>patients have a full chart, so feel free to ask more about their history to better contextualize their symptoms.</li>
          </ul>
          <p>Once you feel ready, put your diagnoses in the boxes below and click finish. You'll soon see how you did.</p>
        </div>

        <div className="diagnostic-section">
          <h3>What does the patient have?</h3>
          <table className="diagnostic-table">
            <tbody className="centered-body">
              <tr className="centered-row">
                <td>
                  <input
                    type="text"
                    placeholder="dx 1"
                    value={guess1}
                    onChange={(e) => setGuess1(e.target.value)}
                    className="diagnostic-input"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="dx 2"
                    value={guess2}
                    onChange={(e) => setGuess2(e.target.value)}
                    className="diagnostic-input"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <button
            onClick={handleSubmitDiagnostics}
            disabled={isSubmitDisabled()}
            className={`submit-button ${isSubmitDisabled() ? 'disabled' : ''}`}
          >
            submit
          </button>
        </div>

        <div className="navigation-buttons">
          <button onClick={handleReturnHome} className="nav-button">
            return home
          </button>
          <button
            onClick={handleStartNewGame}
            className={`nav-button ${!chatHistory.some(message => message.role === 'separator') ? 'disabled' : ''}`}
            disabled={!chatHistory.some(message => message.role === 'separator')}
          >
            next patient
          </button>
        </div>
      </div>
    </div>
  );
};

export default Game;
