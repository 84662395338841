
const OPENAI_API_KEY = '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';

export const openaiCompletions = async (messages, responseFormat = null) => {
  const res = await fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${OPENAI_API_KEY}`,
    },
    body: JSON.stringify({
      model: 'gpt-4o',
      messages: messages,
      max_tokens: 500,
      response_format: responseFormat,
    }),
  });

  if (res.ok) {
    const data = await res.json();
    return data
  }
  return { error: "Failed to get response from OpenAI" };
};