import { BlobServiceClient } from '@azure/storage-blob';

const containerName = 'guess-the-disease-transcripts';

const getBlobStorageClient = async () => {
  try {
    // Get the SAS token for the blob
    const resp = await fetch(`https://alamohealth.azurewebsites.net/api/gensastoken`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });
    const respData = await resp.text();
    const data = JSON.parse(respData);
    const client = new BlobServiceClient(data.blobUrl);
    return client;
  } catch (error) {
    console.error('Error getting Blob Storage Client:', error);
    throw error; // or handle the error as appropriate
  }
};

// Function to get a container client
const getContainerClient = async () => {
  const blobServiceClient = await getBlobStorageClient();
  return blobServiceClient.getContainerClient(containerName);
};

// Utility function to get the contents of a blob
export const getBlobContents = async (blobName) => {
  try {
    const blobClient = getContainerClient().then(containerClient => containerClient.getBlobClient(blobName));
    const blockBlobClient = blobClient.then(blobClient => blobClient.getBlockBlobClient());
    const downloadResp = blockBlobClient.then(blockBlobClient => blockBlobClient.download());
    const blobBody = downloadResp.then(downloadResp => downloadResp.blobBody);
    const blobContent = blobBody.then(blobBody => blobBody.text());
    return blobContent;
  } catch (error) {
    console.error('Error downloading blob:', error);
    throw error;
  }
};

export const uploadBlobContents = async (blobName, contents) => {
  const containerClient = await getContainerClient();
  const blobClient = containerClient.getBlobClient(blobName);
  const blockBlobClient = blobClient.getBlockBlobClient();
  await blockBlobClient.upload(contents, contents.length);
};

export const appendToBlobContents = async (blobName, contents) => {
  const blobContents = await getBlobContents(blobName);
  if (blobContents.length === 0) {
    await uploadBlobContents(blobName, contents);
  } else {
    await uploadBlobContents(blobName, blobContents + "\n" + contents);
  }
};

export const listBlobs = async () => {
  const containerClient = await getContainerClient();
  const blobs = containerClient.listBlobsFlat();
  var blobList = [];
  for await (const blob of blobs) {
    blobList.push(blob);
  }
  return blobList;
};

export const blobExists = async (blobName) => {
  try {
    const containerClient = await getContainerClient();
    const blobClient = containerClient.getBlobClient(blobName);
    return await blobClient.exists();
  } catch (error) {
    console.error('Error checking if blob exists:', error);
    throw error; // or handle the error as appropriate
  }
};

export const touchBlob = async (blobName) => {
  const containerClient = await getContainerClient();
  const blobClient = containerClient.getBlobClient(blobName);
  const blockBlobClient = blobClient.getBlockBlobClient();
  await blockBlobClient.upload(new TextEncoder().encode(''), 0);
};

export const getBlobLineCount = async (blobName) => {
  const blobContents = await getBlobContents(blobName);
  if (blobContents.length === 0) {
    return 0;
  }
  return blobContents.split('\n').length;
};
