import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css'; // Import the CSS file
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { listBlobs, getBlobLineCount, blobExists, touchBlob } from '../utils/blobStorage';
import ReactLoading from 'react-loading';

const Home = () => {
  const [name, setName] = useState(''); // Add state for the name
  const [leaderboard, setLeaderboard] = useState({})
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        setIsLoading(true); // Set loading to true before fetching
        // Get all the folders in the container
        const names = await listBlobs();

        // For each name, get the correct and incorrect counts
        // Create a leaderboard object with name -> {correct_count, incorrect_count}
        var leaderboard = {}
        for await (const name of names) {
          if (name.name.endsWith('/correct.txt')) {
            const physicianName = name.name.split('/')[0]
            if (!(physicianName in leaderboard)) {
              leaderboard[physicianName] = { correct_count: 0, incorrect_count: 0 }
            }
            leaderboard[physicianName].correct_count = await getBlobLineCount(`${name.name}`)
          } else if (name.name.endsWith('/incorrect.txt')) {
            const physicianName = name.name.split('/')[0]
            if (!(physicianName in leaderboard)) {
              leaderboard[physicianName] = { correct_count: 0, incorrect_count: 0 }
            }
            leaderboard[physicianName].incorrect_count = await getBlobLineCount(`${name.name}`)
          }
        }

        setLeaderboard(leaderboard);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };

    fetchLeaderboard();
  }, []);

  const startNewGame = async () => {
    // Create a name/correct.txt and name/incorrect.txt if they don't exist
    if (!await blobExists(`${name}/correct.txt`)) {
      await touchBlob(`${name}/correct.txt`);
    }
    if (!await blobExists(`${name}/incorrect.txt`)) {
      await touchBlob(`${name}/incorrect.txt`);
    }

    // Generate a uuid for the chat id
    navigate(`/game/${uuidv4()}`, { state: { name: name } });
  };

  return (
    <div className="home-container">
      {isLoading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          zIndex: 1000
        }}>
          <ReactLoading type="bubbles" color="#45818e" height={100} width={100} />
          <p style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>loading...</p>
        </div>
      ) : (
        <>
          <div className="content-wrapper" style={{
            display: 'flex',
            gap: '40px',
            width: '100%'
          }}>
            <div className="home-content" style={{ flex: '1' }}>
              <h1 style={{ textTransform: 'lowercase', fontWeight: 'bold', fontSize: '2.5rem' }}>alamo.health</h1>
              <h2 style={{ textTransform: 'lowercase', fontSize: '1.5rem' }}>what are we</h2>
              <p style={{ fontSize: '1.1rem' }}>a health AI company looking to make doctor's lives orders of magnitude easier</p>
              <h2 style={{ textTransform: 'lowercase', fontSize: '1.5rem' }}>what is this</h2>
              <p style={{ fontSize: '1.1rem' }}>a patient simulator game, where we test your diagnostic abilities while we build our own</p>
              <h2 style={{ textTransform: 'lowercase', fontSize: '1.5rem' }}>who are we</h2>
              <p style={{ fontSize: '1.1rem' }}>kvmd, ss, and a few other friends from stanford, cal, and mit</p>
              <p style={{ fontSize: '1.1rem' }}>every time you go through a patient case here you help us help you.</p>
              <p style={{ fontSize: '1.1rem' }}>the core vision of this platform is to help doctors do their jobs 10-15x faster, more conveniently, and be compensated appropriately for their efforts.</p>
              <p style={{ fontSize: '1.1rem' }}>as we grow over the next few months, we will be recruiting clinician advisors to be a part of our journey. the first place we will look is at the leaderboard, so we hope to see you there.</p>
            </div>
            <div className="leaderboard-container" style={{ flex: '1' }}>
              <LeaderboardTable leaderboard={leaderboard} />
            </div>
          </div>
          <div className="input-container">
            <p>if you are a physician:</p>
            <input type="text" placeholder="enter your name" value={name} onChange={(e) => setName(e.target.value)} />
            <button onClick={startNewGame} disabled={!name.trim()}>begin</button>
          </div>
        </>
      )}
    </div>
  );
};

const LeaderboardTable = ({ leaderboard }) => {
  const sortedLeaderboard = Object.entries(leaderboard).sort(([, a], [, b]) => b.correct_count - a.correct_count);

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column',
      width: '100%',  // Take full width of parent
      border: '1px solid rgba(0, 0, 0, 0.5)',
      borderRadius: '4px',
      overflow: 'hidden'
    }}>
      <div style={{ 
        padding: '30px 5px', 
        textAlign: 'center', 
        fontSize: '1.5rem', 
        fontFamily: 'Lato, sans-serif',
        backgroundColor: '#d0e0e3',
        width: '100%',
        borderBottom: '1px solid rgba(0, 0, 0, 0.5)'  // Only need bottom border here
      }}>
        Top Docs
      </div>
      <TableContainer 
        component={Paper} 
        style={{ 
          maxHeight: '400px',
          width: '100%',
          boxShadow: 'none',  // Remove Material-UI's default paper shadow
          borderRadius: 0     // Remove border radius from the table container
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Patients Seen</TableCell>
              <TableCell>% Correct</TableCell>
              <TableCell>Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedLeaderboard.map(([name, counts], index) => {
              const totalCases = counts.correct_count + counts.incorrect_count;
              const percentCorrect = totalCases > 0 ? ((counts.correct_count / totalCases) * 100).toFixed(2) : '0.00';
              return (
                <TableRow key={name}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{totalCases}</TableCell>
                  <TableCell>{percentCorrect}%</TableCell>
                  <TableCell>{counts.correct_count}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Home;
